<template>
	<div class="ParameterYh drag" v-draw id="ParameterYh">
		<div class="param-header">
			<span class="param-header-title">{{projectData.project}}_优化参数</span>
			<div class="param-header-icon" @click="closeCompon">
				<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
			</div>
		</div>
		<div class="evaluate-header-tit">
			热风炉优化参数设置
		</div>
		<div class="ParameterYh-box">
			<!-- 1# -->
			<div class="ParameterYh-box-flex flex align-cen">
				<div class="ParameterYh-box-flex-left publicborder">1#</div>
				<div class="ParameterYh-box-flex-con1 publicborder">
					<div class="con1-column">
						<div class="column1 flex justify-content-cen">
							<div class="column1-row1" style="width:7vw">

								<div class="row1-text">优化时机</div>

								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.YHSJ1 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">自动标志</div>
								<div class="row1-status"
									:style="{background:(infoList.V_RS1.KQZD1_B1 || infoList.V_RS1.MQZD1_B1 ?'#29DFAE':'#00aeff10')}">
								</div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.YHSJ5_B1 ?'#29DFAE':'#f5fbff')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.YHSJ1_B1 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.YHSJ2_B1 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">单烧启停</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.YHSJ4_B1 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">配比波动</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.YHSJ3_B1 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶高限</div>
								<el-tooltip class="item" effect="dark" :content="infoList.V_SOUREC.GDWDH_B1"
									placement="top">
									<div class="row1-text1">{{infoList.V_SOUREC.GDWDH_B1}}℃</div>
								</el-tooltip>
							</div>
							<div class="column1-row1">
								<div class="row1-text">拱顶时间</div>
								<el-tooltip class="item" effect="dark" :content="infoList.V_BCS1.YST2_B1"
									placement="top">
									<div class="row1-text1 ipt"
										@click="toIpt(infoList.V_BCS1.YST2_B1,'拱顶时间','YST2_B1','V_BCS1')">
										{{infoList.V_BCS1.YST2_B1}}S</div>
								</el-tooltip>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气高限</div>
								<el-tooltip class="item" effect="dark" :content="infoList.V_RS1.FQBHZ_B1"
									placement="top">
									<div class="row1-text1">{{infoList.V_RS1.FQBHZ_B1}}℃</div>
								</el-tooltip>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气时间</div>
								<el-tooltip class="item" effect="dark" :content="infoList.V_BCS1.YST3_B1"
									placement="top">
									<div class="row1-text1 ipt"
										@click="toIpt(infoList.V_BCS1.YST3_B1,'废气时间','YST3_B1','V_BCS1')">
										{{infoList.V_BCS1.YST3_B1}}S</div>
								</el-tooltip>
							</div>
						</div>
						<div class="column1 column3 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">顶温稳态精度</div>
								<div class="row1-text1 ">{{infoList.V_BCS1.GDWD_XJ_B1}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS1.YST1_B1,'烧炉时间','YST1_B1','V_BCS1')">
									{{infoList.V_BCS1.YST1_B1}} m</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con2 publicborder">
					<div class="con2-column">
						<div class="column-top">
							<div class="column-row flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">TOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS1.TOP1,'TOP','TOP1','V_BCS1')">
										{{infoList.V_BCS1.TOP1}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风媒比SOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS1.SOP1,'风媒比SOP','SOP1','V_BCS1')">
										{{infoList.V_BCS1.SOP1}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">EOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS1.EOP1,'EOP','EOP1','V_BCS1')">
										{{infoList.V_BCS1.EOP1}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">基本风煤比</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_SOUREC.JBFMB_B1,'基本风媒比','JBFMB_B1','V_SOUREC')">
										{{infoList.V_SOUREC.JBFMB_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">实际风煤比</div>
									<div class="data-num" @click="toCompon(2,'SJFMB_B1','V_BCS1','SJFMB_B1_WF','1#实际风煤比')"
										@dblclick="Cclick(infoList.V_BCS1.SJFMB_B1,'SJFMB_B1', 'V_BCS1', 'SJFMB_B1_WF')">
										{{infoList.V_BCS1.SJFMB_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比范围</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS1.FV_LL_B1,'风煤比范围','FV_LL_B1','V_RS1')">
										{{infoList.V_RS1.FV_LL_B1}}%</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">MAXEOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_GONGGONG.MAXEOP_B1,'MAXEOP','MAXEOP_B1','V_GONGGONG')">
										{{infoList.V_GONGGONG.MAXEOP_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">OLD</div>
									<div class="data-num" @click="toCompon(2,'BCS_ML_B1','V_BCS1','BCS_ML_B1_WF')"
										@dblclick="Cclick(infoList.V_BCS1.BCS_ML_B1,'BCS_ML_B1', 'V_BCS1', 'BCS_ML_B1_WF')">
										{{infoList.V_BCS1.BCS_ML_B1}}</div>
								</div>
							</div>
							<div class="column-row column-row1 flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">最高顶温</div>
									<div class="data-num" @click="toCompon(2,'UHFV1_B1','V_RCL1','UHFV1_B1_WF','1#煤气阀开度线性上限')"
										@dblclick="Cclick(infoList.V_RCL1.UHFV1_B1,'UHFV1_B1', 'V_RCL1', 'UHFV1_B1_WF')">
										{{infoList.V_RCL1.UHFV1_B1}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">当前顶温</div>
									<div class="data-num" @click="toCompon(2,'TE_12_B1','V_SOUREC','TE_12_B1_WF','1#热风炉拱顶温度2')"
										@dblclick="Cclick(infoList.V_SOUREC.TE_12_B1,'TE_12_B1', 'V_SOUREC', 'TE_12_B1_WF')">
										{{infoList.V_SOUREC.TE_12_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">下降幅度</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS1.GDWD_XJ_B1,'下降幅度','GDWD_XJ_B1','V_BCS1')">
										{{infoList.V_BCS1.GDWD_XJ_B1}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比增量</div>
									<div class="data-num" @click="toCompon(2,'BCS_KH_B1','V_BCS1','BCS_KH_B1_WF','1#优化空气量累积')"
										@dblclick="Cclick(infoList.V_BCS1.BCS_KH_B1,'BCS_KH_B1', 'V_BCS1', 'BCS_KH_B1_WF')">
										{{infoList.V_BCS1.BCS_KH_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">参考风煤比</div>
									<div class="data-num" @click="toCompon(2,'KM_MQ_B1','V_RS1','KM_MQ_B1_WF','1#炉参考风煤比')"
										@dblclick="Cclick(infoList.V_RS1.KM_MQ_B1,'KM_MQ_B1', 'V_RS1', 'KM_MQ_B1_WF')">
										{{infoList.V_RS1.KM_MQ_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比上限</div>
									<div class="data-num">{{infoList.V_BCS1.JBFMBH_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比下限</div>
									<div class="data-num">{{infoList.V_BCS1.JBFMBL_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">NEW</div>
									<div class="data-num" @click="toCompon(2,'BCS_MH_B1','V_BCS1','BCS_MH_B1_WF')"
										@dblclick="Cclick(infoList.V_BCS1.BCS_MH_B1,'BCS_MH_B1', 'V_BCS1', 'BCS_MH_B1_WF')">
										{{infoList.V_BCS1.BCS_MH_B1}}</div>
								</div>
							</div>
						</div>
						<div class="column-bot flex">
							<div class="column-bot-left">
								趋势优化
							</div>
							<div class="column-bot-right flex align-cen justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">采样时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS1.AOPH_B1,'采样时间','AOPH_B1','V_RS1')">
										{{infoList.V_RS1.AOPH_B1}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">专家时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS1.AOPL_B1,'专家时间','AOPL_B1','V_RS1')">
										{{infoList.V_RS1.AOPL_B1}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">动态系数</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS1.KM_B1,'动态系数','KM_B1','V_RS1')">
										{{infoList.V_RS1.KM_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">启动值</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS1.GDWDL_B1,'启动值','GDWDL_B1','V_RS1')">
										{{infoList.V_RS1.GDWDL_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">持续时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS1.JBMG01_B1,'持续时间','JBMG01_B1','V_RS1')">
										{{infoList.V_RS1.JBMG01_B1}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">观测输出</div>
									<div class="data-num" @click="toCompon(2,'FT_11N_B1','V_NBCS','FT_11N_B1_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_11N_B1,'FT_11N_B1', 'V_NBCS', 'FT_11N_B1_WF')">
										{{infoList.V_NBCS.FT_11N_B1}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">优化输出</div>
									<div class="data-num" @click="toCompon(2,'FT_12N_B1','V_NBCS','FT_12N_B1_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_12N_B1,'FT_12N_B1', 'V_NBCS', 'FT_12N_B1_WF')">
										{{infoList.V_NBCS.FT_12N_B1}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con3 publicborder">
					<div class="con3-column">
						<div class="con3-column-flex1">
							<div class="con3-column-flex1-text">优化方向及进程</div>
							<div class="con3-column-flex1-progress flex align-cen justify-content-cen">
								<div>0</div>
								<div class="pro">
									<el-progress :show-text="false" :stroke-width="10"
										:percentage="infoList.V_RCL1.UHFV2_B1" :color="customColor"
										v-if="infoList.V_RCL1.UHFV2_B1 >=0 ? false : true">
									</el-progress>
								</div>
								<div>100</div>
							</div>
						</div>
						<div class="con3-column-flex2 flex align-cen justify-content-cen">
							<div class="column-row-data">
								<div class="data-name">强制优化</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.RESET1 ?'#29DFAE':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS1.RESET1,'强制优化','RESET1','V_BCS1')"></div>
							</div>
							<div class="column-row-data">
								<div class="data-name">优化停止</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS1.STOP1 ?'red':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS1.STOP1,'优化停止','STOP1','V_BCS1')"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 2# -->
			<div class="ParameterYh-box-flex flex align-cen">
				<div class="ParameterYh-box-flex-left publicborder">2#</div>
				<div class="ParameterYh-box-flex-con1 publicborder">
					<div class="con1-column">
						<div class="column1 flex justify-content-cen">
							<div class="column1-row1" style="width:7vw">
								<div class="row1-text">优化时机</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.YHSJ2 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">自动标志</div>
								<div class="row1-status"
									:style="{background:(infoList.V_RS2.KQZD1_B2 || infoList.V_RS2.MQZD1_B2 ?'#29DFAE':'#00aeff10')}">
								</div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.YHSJ5_B2 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.YHSJ1_B2 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.YHSJ2_B2 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">单烧启停</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.YHSJ4_B2 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">配比波动</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.YHSJ3_B2 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶高限</div>
								<div class="row1-text1">{{infoList.V_SOUREC.GDWDH_B2}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">拱顶时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS2.YST2_B2,'拱顶时间','YST2_B2','V_BCS2')">
									{{infoList.V_BCS2.YST2_B2}}S</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气高限</div>
								<div class="row1-text1">{{infoList.V_RS2.FQBHZ_B2}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS2.YST3_B2,'废气时间','YST3_B2','V_BCS2')">
									{{infoList.V_BCS2.YST3_B2}}S</div>
							</div>
						</div>
						<div class="column1 column3 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">顶温稳态精度</div>
								<div class="row1-text1 ipt">{{infoList.V_BCS2.GDWD_XJ_B2}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS2.YST1_B2,'烧炉时间','YST1_B2','V_BCS2')">
									{{infoList.V_BCS2.YST1_B2}} m</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con2 publicborder">
					<div class="con2-column">
						<div class="column-top">
							<div class="column-row flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">TOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS2.TOP2,'TOP','TOP2','V_BCS2')">
										{{infoList.V_BCS2.TOP2}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风媒比SOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS2.SOP2,'风媒比SOP','SOP2','V_BCS2')">
										{{infoList.V_BCS2.SOP2}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">EOP</div>
									<div class="data-num ipt" @click="toIpt(infoList.V_BCS2.EOP2,'EOP','EOP','V_BCS2')">
										{{infoList.V_BCS2.EOP2}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">基本风煤比</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_SOUREC.JBFMB_B2,'基本风媒比','JBFMB_B2','V_SOUREC')">
										{{infoList.V_SOUREC.JBFMB_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">实际风煤比</div>
									<div class="data-num" @click="toCompon(2,'SJFMB_B2','V_BCS2','SJFMB_B2_WF','2#实际风煤比')"
										@dblclick="Cclick(infoList.V_BCS2.SJFMB_B2,'SJFMB_B2', 'V_BCS2', 'SJFMB_B2_WF')">
										{{infoList.V_BCS2.SJFMB_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比范围</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS2.FV_LL_B2,'风煤比范围','FV_LL_B2','V_RS2')">
										{{infoList.V_RS2.FV_LL_B2}}%</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">MAXEOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_GONGGONG.MAXEOP_B2,'MAXEOP','MAXEOP_B2','V_GONGGONG')">
										{{infoList.V_GONGGONG.MAXEOP_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">OLD</div>
									<div class="data-num" @click="toCompon(2,'BCS_ML_B2','V_BCS2','BCS_ML_B2_WF')"
										@dblclick="Cclick(infoList.V_BCS2.BCS_ML_B2,'BCS_ML_B2', 'V_BCS2', 'BCS_ML_B2_WF')">
										{{infoList.V_BCS2.BCS_ML_B2}}</div>
								</div>
							</div>
							<div class="column-row column-row1 flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">最高顶温</div>
									<div class="data-num" @click="toCompon(2,'UHFV1_B2','V_RCL2','UHFV1_B2_WF','2#煤气阀开度线性上限')"
										@dblclick="Cclick(infoList.V_RCL2.UHFV1_B2,'UHFV1_B2', 'V_RCL2', 'UHFV1_B2_WF')">
										{{infoList.V_RCL2.UHFV1_B2}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">当前顶温</div>
									<div class="data-num" @click="toCompon(2,'TE_12_B2','V_SOUREC','TE_12_B2_WF','2#热风炉拱顶温度2')"
										@dblclick="Cclick(infoList.V_SOUREC.TE_12_B2,'TE_12_B2', 'V_SOUREC', 'TE_12_B2_WF')">
										{{infoList.V_SOUREC.TE_12_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">下降幅度</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS2.GDWD_XJ_B2,'下降幅度','GDWD_XJ_B2','V_BCS2')">
										{{infoList.V_BCS2.GDWD_XJ_B2}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比增量</div>
									<div class="data-num" @click="toCompon(2,'BCS_KH_B2','V_BCS2','BCS_KH_B2_WF','2#优化空气量累积')"
										@dblclick="Cclick(infoList.V_BCS2.BCS_KH_B2,'BCS_KH_B2', 'V_BCS2', 'BCS_KH_B2_WF')">
										{{infoList.V_BCS2.BCS_KH_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">参考风煤比</div>
									<div class="data-num" @click="toCompon(2,'KM_MQ_B2','V_RS2','KM_MQ_B2_WF','2#炉参考风煤比')"
										@dblclick="Cclick(infoList.V_RS2.KM_MQ_B2,'KM_MQ_B2', 'V_RS2', 'KM_MQ_B2_WF')">
										{{infoList.V_RS2.KM_MQ_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比上限</div>
									<div class="data-num">{{infoList.V_BCS2.JBFMBH_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比下限</div>
									<div class="data-num">{{infoList.V_BCS2.JBFMBL_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">NEW</div>
									<div class="data-num" @click="toCompon(2,'BCS_MH_B2','V_BCS2','BCS_MH_B2_WF')"
										@dblclick="Cclick(infoList.V_BCS2.BCS_MH_B2,'BCS_MH_B2', 'V_BCS2', 'BCS_MH_B2_WF')">
										{{infoList.V_BCS2.BCS_MH_B2}}</div>
								</div>
							</div>
						</div>
						<div class="column-bot flex">
							<div class="column-bot-left">
								趋势优化
							</div>
							<div class="column-bot-right flex align-cen justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">采样时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS2.AOPH_B2,'采样时间','AOPH_B2','V_RS2')">
										{{infoList.V_RS2.AOPH_B2}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">专家时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS2.AOPL_B2,'专家时间','AOPL_B2','V_RS2')">
										{{infoList.V_RS2.AOPL_B2}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">动态系数</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS2.KM_B2,'动态系数','KM_B2','V_RS2')">
										{{infoList.V_RS2.KM_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">启动值</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS2.GDWDL_B2,'启动值','GDWDL_B2','V_RS2')">
										{{infoList.V_RS2.GDWDL_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">持续时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS2.JBMG01_B2,'持续时间','JBMG01_B2','V_RS2')">
										{{infoList.V_RS2.JBMG01_B2}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">观测输出</div>
									<div class="data-num" @click="toCompon(2,'FT_11N_B2','V_NBCS','FT_11N_B2_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_11N_B2,'FT_11N_B2', 'V_NBCS', 'FT_11N_B2_WF')">
										{{infoList.V_NBCS.FT_11N_B2}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">优化输出</div>
									<div class="data-num" @click="toCompon(2,'FT_12N_B2','V_NBCS','FT_12N_B2_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_12N_B2,'FT_12N_B2', 'V_NBCS', 'FT_12N_B2_WF')">
										{{infoList.V_NBCS.FT_12N_B2}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con3 publicborder">
					<div class="con3-column">
						<div class="con3-column-flex1">
							<div class="con3-column-flex1-text">优化方向及进程</div>
							<div class="con3-column-flex1-progress flex align-cen justify-content-cen">
								<div>0</div>
								<div class="pro">
									<el-progress :show-text="false" :stroke-width="10"
										:percentage="infoList.V_RCL2.UHFV2_B2" :color="customColor"
										v-if="infoList.V_RCL2.UHFV2_B2 >=0 ? false : true">
									</el-progress>
								</div>
								<div>100</div>
							</div>
						</div>
						<div class="con3-column-flex2 flex align-cen justify-content-cen">
							<div class="column-row-data">
								<div class="data-name">强制优化</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.RESET2 ?'#29DFAE':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS2.RESET2,'强制优化','RESET2','V_BCS2')"></div>
							</div>
							<div class="column-row-data">
								<div class="data-name">优化停止</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS2.STOP2 ?'red':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS2.STOP2,'优化停止','STOP2','V_BCS2')"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 3# -->
			<div class="ParameterYh-box-flex flex align-cen">
				<div class="ParameterYh-box-flex-left publicborder">3#</div>
				<div class="ParameterYh-box-flex-con1 publicborder">
					<div class="con1-column">
						<div class="column1 flex justify-content-cen">
							<div class="column1-row1" style="width:7vw">
								<div class="row1-text">优化时机</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.YHSJ3 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">自动标志</div>
								<div class="row1-status"
									:style="{background:(infoList.V_RS3.KQZD1_B3 || infoList.V_RS3.MQZD1_B3 ?'#29DFAE':'#00aeff10')}">
								</div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.YHSJ5_B3 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.YHSJ1_B3 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.YHSJ2_B3 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">单烧启停</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.YHSJ4_B3 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">配比波动</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.YHSJ3_B3 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶高限</div>
								<div class="row1-text1">{{infoList.V_SOUREC.GDWDH_B3}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">拱顶时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS3.YST2_B3,'拱顶时间','YST2_B3','V_BCS3')">
									{{infoList.V_BCS3.YST2_B3}}S</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气高限</div>
								<div class="row1-text1">{{infoList.V_RS3.FQBHZ_B3}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS3.YST3_B3,'废气时间','YST3_B3','V_BCS3')">
									{{infoList.V_BCS3.YST3_B3}}S</div>
							</div>
						</div>
						<div class="column1 column3 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">顶温稳态精度</div>
								<div class="row1-text1">{{infoList.V_BCS3.GDWD_XJ_B3}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS3.YST1_B3,'烧炉时间','YST1_B3','V_BCS3')">
									{{infoList.V_BCS3.YST1_B3}} m</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con2 publicborder">
					<div class="con2-column">
						<div class="column-top">
							<div class="column-row flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">TOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS3.TOP3,'TOP','TOP3','V_BCS3')">
										{{infoList.V_BCS3.TOP3}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风媒比SOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS3.SOP3,'风媒比SOP','SOP3','V_BCS3')">
										{{infoList.V_BCS3.SOP3}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">EOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS3.EOP3,'EOP','EOP3','V_BCS3')">
										{{infoList.V_BCS3.EOP3}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">基本风煤比</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_SOUREC.JBFMB_B3,'基本风媒比','JBFMB_B3','V_SOUREC')">
										{{infoList.V_SOUREC.JBFMB_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">实际风煤比</div>
									<div class="data-num" @click="toCompon(2,'SJFMB_B3','V_BCS3','SJFMB_B3_WF','3#实际风煤比')"
										@dblclick="Cclick(infoList.V_BCS3.SJFMB_B3,'SJFMB_B3', 'V_BCS3', 'SJFMB_B3_WF')">
										{{infoList.V_BCS3.SJFMB_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比范围</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS3.FV_LL_B3,'风煤比范围','FV_LL_B3','V_RS3')">
										{{infoList.V_RS3.FV_LL_B3}}%</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">MAXEOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_GONGGONG.MAXEOP_B3,'MAXEOP','MAXEOP_B3','V_GONGGONG')">
										{{infoList.V_GONGGONG.MAXEOP_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">OLD</div>
									<div class="data-num" @click="toCompon(2,'BCS_ML_B3','V_BCS3','BCS_ML_B3_WF')"
										@dblclick="Cclick(infoList.V_BCS3.BCS_ML_B3,'BCS_ML_B3', 'V_BCS3', 'BCS_ML_B3_WF')">
										{{infoList.V_BCS3.BCS_ML_B3}}</div>
								</div>
							</div>
							<div class="column-row column-row1 flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">最高顶温</div>
									<div class="data-num" @click="toCompon(2,'UHFV1_B3','V_RCL3','UHFV1_B3_WF','3#煤气阀开度线性上限')"
										@dblclick="Cclick(infoList.V_RCL3.UHFV1_B3,'UHFV1_B3', 'V_RCL3', 'UHFV1_B3_WF')">
										{{infoList.V_RCL3.UHFV1_B3}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">当前顶温</div>
									<div class="data-num" @click="toCompon(2,'TE_12_B3','V_SOUREC','TE_12_B3_WF','3#热风炉拱顶温度2')"
										@dblclick="Cclick(infoList.V_SOUREC.TE_12_B3,'TE_12_B3', 'V_SOUREC', 'TE_12_B3_WF')">
										{{infoList.V_SOUREC.TE_12_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">下降幅度</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS3.GDWD_XJ_B3,'下降幅度','GDWD_XJ_B3','V_BCS3')">
										{{infoList.V_BCS3.GDWD_XJ_B3}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比增量</div>
									<div class="data-num" @click="toCompon(2,'BCS_KH_B3','V_BCS3','BCS_KH_B3_WF','3#优化空气量累积')"
										@dblclick="Cclick(infoList.V_BCS3.BCS_KH_B3,'BCS_KH_B3', 'V_BCS3', 'BCS_KH_B3_WF')">
										{{infoList.V_BCS3.BCS_KH_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">参考风煤比</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS3.KM_MQ_B3,'参考风煤比','KM_MQ_B3','V_RS3')">
										{{infoList.V_RS3.KM_MQ_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比上限</div>
									<div class="data-num">{{infoList.V_BCS3.JBFMBH_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比下限</div>
									<div class="data-num">{{infoList.V_BCS3.JBFMBL_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">NEW</div>
									<div class="data-num" @click="toCompon(2,'BCS_MH_B3','V_BCS3','BCS_MH_B3_WF')"
										@dblclick="Cclick(infoList.V_BCS3.BCS_MH_B3,'BCS_MH_B3', 'V_BCS3', 'BCS_MH_B3_WF')">
										{{infoList.V_BCS3.BCS_MH_B3}}</div>
								</div>
							</div>
						</div>
						<div class="column-bot flex">
							<div class="column-bot-left">
								趋势优化
							</div>
							<div class="column-bot-right flex align-cen justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">采样时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS3.AOPH_B3,'采样时间','AOPH_B3','V_RS3')">
										{{infoList.V_RS3.AOPH_B3}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">专家时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS3.AOPL_B3,'专家时间','AOPL_B3','V_RS3')">
										{{infoList.V_RS3.AOPL_B3}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">动态系数</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS3.KM_B3,'动态系数','KM_B3','V_RS3')">
										{{infoList.V_RS3.KM_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">启动值</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS3.GDWDL_B3,'启动值','GDWDL_B3','V_RS3')">
										{{infoList.V_RS3.GDWDL_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">持续时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS3.JBMG01_B3,'持续时间','JBMG01_B3','V_RS3')">
										{{infoList.V_RS3.JBMG01_B3}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">观测输出</div>
									<div class="data-num" @click="toCompon(2,'FT_11N_B3','V_NBCS','FT_11N_B3_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_11N_B3,'FT_11N_B3', 'V_NBCS', 'FT_11N_B3_WF')">
										{{infoList.V_NBCS.FT_11N_B3}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">优化输出</div>
									<div class="data-num" @click="toCompon(2,'FT_12N_B3','V_NBCS','FT_12N_B3_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_12N_B3,'FT_12N_B3', 'V_NBCS', 'FT_12N_B3_WF')">
										{{infoList.V_NBCS.FT_12N_B3}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con3 publicborder">
					<div class="con3-column">
						<div class="con3-column-flex1">
							<div class="con3-column-flex1-text">优化方向及进程</div>
							<div class="con3-column-flex1-progress flex align-cen justify-content-cen">
								<div>0</div>
								<div class="pro">
									<el-progress :show-text="false" :stroke-width="10"
										:percentage="infoList.V_RCL3.UHFV2_B3" :color="customColor"
										v-if="infoList.V_RCL3.UHFV2_B3 >=0 ? false : true">
									</el-progress>
								</div>
								<div>100</div>
							</div>
						</div>
						<div class="con3-column-flex2 flex align-cen justify-content-cen">
							<div class="column-row-data">
								<div class="data-name">强制优化</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.RESET3 ?'#29DFAE':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS3.RESET3,'强制优化','RESET3','V_BCS3')"></div>
							</div>
							<div class="column-row-data">
								<div class="data-name">优化停止</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS3.STOP3 ?'red':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS3.STOP3,'强制优化','STOP3','V_BCS3')"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 4# -->
			<div class="ParameterYh-box-flex flex align-cen" style="border-bottom: 1px solid #00e5ff20">
				<div class="ParameterYh-box-flex-left publicborder">4#</div>
				<div class="ParameterYh-box-flex-con1 publicborder">
					<div class="con1-column">
						<div class="column1 flex justify-content-cen">
							<div class="column1-row1" style="width:7vw">
								<div class="row1-text">优化时机</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.YHSJ4 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">自动标志</div>
								<div class="row1-status"
									:style="{background:(infoList.V_RS4.KQZD1_B4 || infoList.V_RS4.MQZD1_B4 ?'#29DFAE':'#00aeff10')}">
								</div>
							</div>
							<div class="column1-row1 column1-row2" style="width:4vw">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.YHSJ5_B4 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.YHSJ1_B4 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气温度</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.YHSJ2_B4 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">单烧启停</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.YHSJ4_B4 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">配比波动</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.YHSJ3_B4 ?'#29DFAE':'#00aeff10')}"></div>
							</div>
						</div>
						<div class="column1 column2 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">拱顶高限</div>
								<div class="row1-text1">{{infoList.V_SOUREC.GDWDH_B4}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">拱顶时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS4.YST2_B4,'拱顶时间','YST2_B4','V_BCS4')">
									{{infoList.V_BCS4.YST2_B4}}S</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气高限</div>
								<div class="row1-text1">{{infoList.V_RS4.FQBHZ_B4}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">废气时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS4.YST3_B4,'废气时间','YST3_B4','V_BCS4')">
									{{infoList.V_BCS4.YST3_B4}}S</div>
							</div>
						</div>
						<div class="column1 column3 flex justify-content-cen">
							<div class="column1-row1 column1-row1-frist">
								<div class="row1-text">顶温稳态精度</div>
								<div class="row1-text1">{{infoList.V_BCS4.GDWD_XJ_B4}}℃</div>
							</div>
							<div class="column1-row1">
								<div class="row1-text">烧炉时间</div>
								<div class="row1-text1 ipt"
									@click="toIpt(infoList.V_BCS4.YST1_B4,'烧炉时间','YST1_B4','V_BCS4')">
									{{infoList.V_BCS4.YST1_B4}} m</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con2 publicborder">
					<div class="con2-column">
						<div class="column-top">
							<div class="column-row flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">TOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS4.TOP4,'TOP','TOP4','V_BCS4')">
										{{infoList.V_BCS4.TOP4}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风媒比SOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS4.SOP4,'风媒比SOP','SOP4','V_BCS4')">
										{{infoList.V_BCS4.SOP4}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">EOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS4.EOP4,'EOP','EOP4','V_BCS4')">
										{{infoList.V_BCS4.EOP4}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">基本风煤比</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_SOUREC.JBFMB_B4,'基本风媒比','JBFMB_B4','V_SOUREC')">
										{{infoList.V_SOUREC.JBFMB_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">实际风煤比</div>
									<div class="data-num" @click="toCompon(2,'SJFMB_B4','V_BCS4','SJFMB_B4_WF','4#实际风煤比')"
										@dblclick="Cclick(infoList.V_BCS4.SJFMB_B4,'SJFMB_B4', 'V_BCS4', 'SJFMB_B4_WF')">
										{{infoList.V_BCS4.SJFMB_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比范围</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS4.FV_LL_B4,'风煤比范围','FV_LL_B4','V_RS4')">
										{{infoList.V_RS4.FV_LL_B4}}%</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">MAXEOP</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_GONGGONG.MAXEOP_B4,'MAXEOP','MAXEOP_B4','V_GONGGONG')">
										{{infoList.V_GONGGONG.MAXEOP_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">OLD</div>
									<div class="data-num" @click="toCompon(2,'BCS_ML_B4','V_BCS4','BCS_ML_B4_WF')"
										@dblclick="Cclick(infoList.V_BCS4.BCS_ML_B4,'BCS_ML_B4', 'V_BCS4', 'BCS_ML_B4_WF')">
										{{infoList.V_BCS4.BCS_ML_B4}}</div>
								</div>
							</div>
							<div class="column-row column-row1 flex justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">最高顶温</div>
									<div class="data-num" @click="toCompon(2,'UHFV1_B4','V_RCL4','UHFV1_B4_WF','4#煤气阀开度线性上限')"
										@dblclick="Cclick(infoList.V_RCL4.UHFV1_B4,'UHFV1_B4', 'V_RCL4', 'UHFV1_B4_WF')">
										{{infoList.V_RCL4.UHFV1_B4}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">当前顶温</div>
									<div class="data-num" @click="toCompon(2,'TE_12_B4','V_SOUREC','TE_12_B4_WF','4#热风炉拱顶温度2')"
										@dblclick="Cclick(infoList.V_SOUREC.TE_12_B4,'TE_12_B4', 'V_SOUREC', 'TE_12_B4_WF')">
										{{infoList.V_SOUREC.TE_12_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">下降幅度</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_BCS4.GDWD_XJ_B4,'下降幅度','GDWD_XJ_B4','V_BCS4')">
										{{infoList.V_BCS4.GDWD_XJ_B4}}℃</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比增量</div>
									<div class="data-num">{{infoList.V_BCS4.BCS_KH_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">参考风煤比</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS4.KM_MQ_B4,'参考风煤比','KM_MQ_B4','V_RS4')">
										{{infoList.V_RS4.KM_MQ_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比上限</div>
									<div class="data-num">{{infoList.V_BCS4.JBFMBH_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">风煤比下限</div>
									<div class="data-num">{{infoList.V_BCS4.JBFMBL_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">NEW</div>
									<div class="data-num" @click="toCompon(2,'BCS_MH_B4','V_BCS4','BCS_MH_B4_WF')"
										@dblclick="Cclick(infoList.V_BCS4.BCS_MH_B4,'BCS_MH_B4', 'V_BCS4', 'BCS_MH_B4_WF')">
										{{infoList.V_BCS4.BCS_MH_B4}}</div>
								</div>
							</div>
						</div>
						<div class="column-bot flex">
							<div class="column-bot-left">
								趋势优化
							</div>
							<div class="column-bot-right flex align-cen justify-content-cen">
								<div class="column-row-data column-row-frist">
									<div class="data-name">采样时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS4.AOPH_B4,'采样时间','AOPH_B4','V_RS4')">
										{{infoList.V_RS4.AOPH_B4}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">专家时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS4.AOPL_B4,'专家时间','AOPL_B4','V_RS4')">
										{{infoList.V_RS4.AOPL_B4}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">动态系数</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS4.KM_B4,'动态系数','KM_B4','V_RS4')">
										{{infoList.V_RS4.KM_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">启动值</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS4.GDWDL_B4,'启动值','GDWDL_B4','V_RS4')">
										{{infoList.V_RS4.GDWDL_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">持续时间</div>
									<div class="data-num ipt"
										@click="toIpt(infoList.V_RS4.JBMG01_B4,'持续时间','JBMG01_B4','V_RS4')">
										{{infoList.V_RS4.JBMG01_B4}}S</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">观测输出</div>
									<div class="data-num" @click="toCompon(2,'FT_11N_B4','V_NBCS','FT_11N_B4_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_11N_B4,'FT_11N_B4', 'V_NBCS', 'FT_11N_B4_WF')">
										{{infoList.V_NBCS.FT_11N_B4}}</div>
								</div>
								<div class="column-row-data">
									<div class="data-name">优化输出</div>
									<div class="data-num" @click="toCompon(2,'FT_12N_B4','V_NBCS','FT_12N_B4_WF')"
										@dblclick="Cclick(infoList.V_NBCS.FT_12N_B4,'FT_12N_B4', 'V_NBCS', 'FT_12N_B4_WF')">
										{{infoList.V_NBCS.FT_12N_B4}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="ParameterYh-box-flex-con3 publicborder">
					<div class="con3-column">
						<div class="con3-column-flex1">
							<div class="con3-column-flex1-text">优化方向及进程</div>
							<div class="con3-column-flex1-progress flex align-cen justify-content-cen">
								<div>0</div>
								<div class="pro">
									<el-progress :show-text="false" :stroke-width="10"
										:percentage="infoList.V_RCL4.UHFV2_B4" :color="customColor"
										v-if="infoList.V_RCL4.UHFV2_B4 >=0 ? false : true">
									</el-progress>
								</div>
								<div>100</div>
							</div>
						</div>
						<div class="con3-column-flex2 flex align-cen justify-content-cen">
							<div class="column-row-data">
								<div class="data-name">强制优化</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.RESET4 ?'#29DFAE':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS4.RESET4,'强制优化','RESET4','V_BCS4')"></div>
							</div>
							<div class="column-row-data">
								<div class="data-name">优化停止</div>
								<div class="row1-status"
									:style="{background:(infoList.V_BCS4.STOP4 ?'red':'#00aeff10')}"
									@click="toIpt(infoList.V_BCS4.STOP4,'强制优化','STOP4','V_BCS4')"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		<Historical v-if="isHshow" @sendStatus='isClose1' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
	</div>
</template>
<script>
	import inputVal from "@/components/inputVal.vue" //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	export default {
		name: "ParameterYh",
		components: {
			inputVal,
			Historical
		},
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},

		data: () => {
			return {
				chName: '',
				percentage: 20,
				customColor: 'rgb(41, 223, 174)',
				historyname: '',
				nodename: '',
				Aname: '',
				isHshow: false,
				isIndex: '',
				projectData: {},
				authInfo: '',
				UserInfo: '',
				spotArr: [],
				grouptime: null,
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n
				},
				deep: true // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		},
		methods: {
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			//    打开历史趋势组件
			toCompon(key, name, name2, name3, name4) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true
						case 1:
							return this.isFshow = true
						case 2:
							return this.isHshow = true, this.chName = name4
					}
				}, 300)
			},
			//    打开输入框组件。
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node)
			},
			isClose1() {
				this.isHshow = false
			},
			closeCompon() {
				this.$emit('sendStatus', 'ParameterYh', false)
			},
		}
	}
</script>
<style lang="scss" scoped>
	.ParameterYh {
		width: 66vw;
		height: 78vh;
		// background-color: #e9f7ff;
		background-image: url('~@/assets/images/anquanshezhiditu.png');
		background-size: 100% 100%;
		border-radius: 8px;
		cursor: move;
		border-radius: 5px;

		.param-header {
			height: 50px;
			//  background: #5DCEF3;
			line-height: 50px;
			text-align: center;
			color: #041a28;
			font-size: 24px;
			position: relative;
			border-radius: 5px 5px 0 0;

			.param-header-title {
				width: 179px;
				height: 28px;
				font-family: MicrosoftYaHei;
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				line-height: 20px;
				letter-spacing: 0px;
				color: #0ef7ff;
			}

			.param-header-icon {
				width: 2vw;
				height: 2vh;
				position: absolute;
				right: 1vw;
				margin-top: -5vh;
				cursor: pointer;
			}
		}

		.evaluate-header-tit {
			font-size: 1.3vw;
			color: #eff7fa;
			text-align: center;

			letter-spacing: 11px;
		}

		.ipt {
			color: #01ffba !important;
		}

		.ParameterYh-box {
			width: 65vw;
			margin: 0 auto;
			height: 64vh;

			.ParameterYh-box-flex {
				width: 100%;
				height: 16vh;
				border-right: 1px solid #00e5ff20;

				.ParameterYh-box-flex-left {
					width: 2vw;
					text-align: center;
					line-height: 16vh;
					color: #d5fffe;
					font-size: 0.8vw;
					background: #00aeff30;
				}

				.publicborder {
					border: 1px solid #00e5ff20;
					height: inherit;
					border-right: 0;
					box-sizing: border-box;
					border-bottom: 0;

				}

				.ParameterYh-box-flex-con1 {
					width: 16vw;
					height: 16vh;

					.con1-column {
						width: 100%;
						margin: 0 auto;
						height: 16vh;

						.column1 {
							width: inherit;

							.column1-row1 {
								text-align: center;

								.row1-text {
									font-size: 0.8vw;
									color: #8aeaff;
									line-height: 2.5vh;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									//  letter-spacing: -1px;
									//  line-height: 18px;
								}

								.row1-status {

									height: 1.5vh;
									// border: solid 1px #00aeff;
									box-sizing: border-box;
									cursor: pointer;
									margin-right: 0.2vw;
								}
							}
						}

						.column2 {

							.column1-row1 {
								width: 3.75vw;

								.row1-status {
									width: 3.5vw;
									cursor: pointer;
								}

								.row1-text1 {
									font-size: 0.8vw;
									line-height: 1.5vh;
									// letter-spacing: -1px;
									color: #8aeaff;
									cursor: pointer;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;

								}
							}

							.column1-row1-frist {
								margin-left: 0;
							}
						}

						.column3 {

							.column1-row1 {
								width: 116.5px;

								.row1-text1 {
									font-size: 0.8vw;
									line-height: 1vh;
									color: #8aeaff;
									cursor: pointer;
								}
							}
						}
					}
				}

				.ParameterYh-box-flex-con2 {
					width: 32vw;

					.con2-column {

						//  padding-top: 16px;
						.column-top {
							height: 9vh;
							border-bottom: 1px solid #00e5ff20;
							box-sizing: border-box;
							padding-top: .5vh;

							.column-row-data {
								text-align: center;
								margin-left: 6px;

								.data-name {
									font-size: 0.8vw;
									line-height: 2vh;
									letter-spacing: 0px;
									color: #8aeaff;
								}

								.data-num {
									font-size: 0.8vw;
									line-height: 2vh;
									letter-spacing: 0px;
									color: #2fc3e3;
									cursor: pointer;
								}
							}

							.column-row-frist {
								margin-left: 0;
							}

						}

						.column-bot {
							height: 7vh;
							align-items: center;

							.column-bot-left {
								width: 4vw;
								height: 5vh;
								text-align: center;
								background-color: #00aeff40;
								line-height: 5vh;
								color: #f2fcff;
								font-size: .7vw;
							}

							.column-bot-right {
								width: 28vw;

								.column-row-data {
									text-align: center;
									margin-left: 10px;

									.data-name {
										font-size: 0.8vw;
										line-height: 2.5vh;
										letter-spacing: 0px;
										color: #8aeaff;
									}

									.data-num {
										font-size: 0.8vw;
										line-height: 18px;
										letter-spacing: 0px;
										color: #8aeaff;
										cursor: pointer;
									}
								}

								.column-row-frist {
									margin-left: 0;
								}
							}
						}
					}
				}

				.ParameterYh-box-flex-con3 {
					width: 15vw;

					.con3-column {
						text-align: center;
						padding-top: 4vh;

						.con3-column-flex1 {
							.con3-column-flex1-text {
								font-size: 0.8vw;
								line-height: 2vh;
								letter-spacing: -1px;
								color: #8aeaff;
							}

							.con3-column-flex1-progress {
								font-size: 0.8vw;
								color: #8aeaff;

								.pro {
									margin: 0 10px;
									width: 100px;
								}
							}

						}

						.con3-column-flex2 {
							.column-row-data {
								text-align: center;
								margin-left: 10px;

								.data-name {
									font-size: 0.8vw;
									line-height: 2vh;
									letter-spacing: 0px;
									color: #8aeaff;
								}

								.row1-status {
									width: 56px;
									height: 18px;
									// border: solid 1px #bbd6e5;
									box-sizing: border-box;
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
